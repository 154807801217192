import axios from "axios"

const G_KEY = 'key=AIzaSyAJo8tKwsWE95U3ROttVw9i1aQoGT_SGxg'
const G_URL = 'https://www.googleapis.com/blogger/v3/blogs/'
const G_BLOGGERID = '7843036904092879588'

const apiGetBlogger = `${G_URL}${G_BLOGGERID}?${G_KEY}`
const apiGetPages = `${G_URL}${G_BLOGGERID}/pages?${G_KEY}`
const getPageId = async (id) => await axios.get(`${G_URL}${G_BLOGGERID}/pages/${id}?${G_KEY}`)

const getPosts = async ($page = 0) => {
    const getFields = 'fields=items(title,id,published,content,labels),nextPageToken'
    return await axios.get(`${G_URL}${G_BLOGGERID}/posts?${G_KEY}&maxResults=10&startIndex=${$page}&${getFields}`)
}

const getPostID = async (id) => await axios.get(`${G_URL}${G_BLOGGERID}/posts/${id}?${G_KEY}`)
const searchPostbyTags = async (tag) => await axios.get(`${G_URL}${G_BLOGGERID}/posts?lable=${tag}&${G_KEY}`)

export {
    apiGetBlogger, apiGetPages, getPageId, getPosts, getPostID, searchPostbyTags
}