import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Row, Spinner } from 'react-bootstrap';
import { getPageId } from '../services/api';
import { Helmet } from 'react-helmet-async';

function PagesScreen(props) {
    const { id } = useParams();
    const [content, setContent] = useState();
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        console.log('PagesScreen ', id);
        getPageId(id).then(res => setContent(res.data)).catch(error => navigate('/'))
    }, [id, navigate, location]);

    return (
        (content) ? (
            <div>
                <Helmet>
                    <title>{content.title}</title>
                    <meta property="og:title" content={content.title} />
                </Helmet>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Trang Chủ</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: location }}>
                        {content.title}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Row className="justify-content-md-center">
                    <h1>{content.title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: content.content }} />
                </Row>
            </div>
        ) : (

            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )

    );
}

export default PagesScreen;