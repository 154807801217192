import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import FooterCom from '../companent/FooterCom';
import HeaderCom from '../companent/HeaderCom';

function Master(props) {
    return (
        <>
            <HeaderCom />
            <Container className='App'>
                <Outlet />
            </Container>
            <FooterCom />
        </>
    );
}

export default Master;