import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate, useParams, NavLink } from 'react-router-dom';
import { getPostID } from '../services/api';

function PostScreen(props) {
    const { id } = useParams();
    const [content, setContent] = useState();
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        console.log('PostScreen called ', id);
        getPostID(id).then(res => setContent(res.data)).catch(error => navigate('/'))
    }, [id, navigate, location])

    return (
        (content) ? (
            <>
                <Helmet>
                    <title>{content.title}</title>
                    <meta property="og:title" content={content.title} />
                </Helmet>
                <Row className="justify-content-md-center">
                    <Col xs lg="8">
                        <Breadcrumb className='breadcrumb'>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Trang Chủ</Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Bài Viết</Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: location }}>
                                {content.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>{content.title}</h2>
                        <div>
                            {content?.labels.map((item, index) => (
                                <NavLink className='linkTitle' key={index} to={`/tags/${item}`}># {item} </NavLink>
                            ))}
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: content.content }} />
                    </Col>
                </Row>
            </>

        ) : (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )

    );
}

export default PostScreen;