import React from 'react';
import { Row } from 'react-bootstrap';

function FooterCom(props) {
    return (
        <>
            <hr />
            <Row >
                <p className="text-center"> Copyright by Me</p>
            </Row>
        </>
    );
}

export default FooterCom;