import { useEffect, useState } from 'react';
import { Container, Navbar, Nav, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios"
import slugify from "slugify";

import { apiGetBlogger, apiGetPages } from '../services/api';
import { Helmet } from 'react-helmet-async';

function HeaderCom() {
    const [page, setPage] = useState()
    const [info, setInfo] = useState()

    useEffect(() => {
        axios.get(apiGetBlogger)
            .then((response) => setInfo(response.data))
            .catch((error) => console.log(error))

        axios.get(apiGetPages)
            .then((response) => setPage(response.data))
            .catch((error) => console.log(error))
    }, [])

    return (
        <>
            <Helmet>
                <title>{info?.name}</title>
            </Helmet>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to='/'>{info?.name} </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {page?.items.map(item => <Nav.Link key={item.id} as={Link} to={`pages/${slugify(item.title, { lower: true, trim: true })}/${item.id}`}>{item.title}</Nav.Link>)}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default HeaderCom;