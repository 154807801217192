import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import './assets/css/App.css';
import { HomeScreen, Master, PagesScreen, PostScreen, TagsScreen } from "./pages";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Master />} action={() => { console.log('Master load'); return }} >
      <Route index element={<HomeScreen />} />
      <Route path="/pages/:str/:id" element={<PagesScreen />} />
      <Route path="/bai-viet/:str/:id" element={<PostScreen />} />
      <Route path="/tags/:tags" element={<TagsScreen />} />
    </Route>
  )
);

function App() {

  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
