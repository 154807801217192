import React from 'react';
import { Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import slugify from 'slugify';

function ContentCom(props) {
    const data = props.data

    return (
        data.items.map(post => (
            <Row key={post.id}>
                <NavLink className='linkTitle' to={`/bai-viet/${slugify(post.title, { lower: true, trim: true })}/${post.id}`} >
                    <h2 className='title'>{post.title} </h2>
                </NavLink>
                <div>
                    {post?.labels.map((item, index) => (
                        <NavLink className='linkTitle' key={index} to={`/tags/${item}`}># {item} </NavLink>
                    ))}
                </div>
                <p dangerouslySetInnerHTML={{ __html: post.content }} />
            </Row>
        ))
    );
}

export default ContentCom;