import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { searchPostbyTags } from '../services/api';
import { Col, Pagination, Row, Spinner } from 'react-bootstrap';
import ContentCom from '../companent/ContentCom';

function TagsScreen(props) {
    const { tags } = useParams();
    const [content, setContent] = useState();
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        console.log('Tags screen', tags);
        searchPostbyTags(tags).then(res => setContent(res.data)).catch(error => navigate('/'))
    }, [tags, navigate, location]);

    return (
        (content) ? (
            <>
                <Row className="justify-content-md-center">
                    <Col xs lg="8">
                        <ContentCom data={content} />
                    </Col>
                </Row >
                {(content.nextPageToken) ? (
                    <Row className="justify-content-md-center text-center">
                        <Col xs lg="1">
                            <Pagination className='text-center'>
                                <Pagination.Prev title='Trước' onClick={() => console.log('Page next')} >
                                    Trước
                                </Pagination.Prev>
                                <Pagination.Next title='Tiếp' onClick={() => console.log('Page pre')} >
                                    Tiếp
                                </Pagination.Next>
                            </Pagination>
                        </Col>
                    </Row>
                ) : ('')}
            </>
        ) : (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span >
            </Spinner >
        )
    );
}

export default TagsScreen;