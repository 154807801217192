import React, { useEffect, useState } from 'react';
import { Row, Spinner, Col, Pagination } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ContentCom from '../companent/ContentCom';

import { getPosts } from '../services/api';

function HomeScreen(props) {
    let { page } = useParams();
    const [posts, setPosts] = useState();

    useEffect(() => {
        getPosts().then(res => setPosts(res.data)).catch(err => console.log(err))
    }, [page]);

    return (
        (posts) ? (
            <>
                <Row className="justify-content-md-center">
                    <Col xs lg="8">
                        <ContentCom data={posts} />
                    </Col>
                </Row >
                {(posts.nextPageToken) ? (
                    <Row className="justify-content-md-center text-center">
                        <Col xs lg="1">
                            <Pagination className='text-center'>
                                <Pagination.Prev title='Trước' onClick={() => console.log('Page next')} >
                                    Trước
                                </Pagination.Prev>
                                <Pagination.Next title='Tiếp' onClick={() => console.log('Page pre')} >
                                    Tiếp
                                </Pagination.Next>
                            </Pagination>
                        </Col>
                    </Row>
                ) : ('')}

            </>
        ) : (
            <Row>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row >
        )

    );
}

export default HomeScreen;